




















import { Component, Vue } from "vue-property-decorator";
import QuestionnaireTable from "@/components/facts/QuestionnaireTable.vue";

@Component({
	components: { QuestionnaireTable },
})
export default class Facts extends Vue {}
