























import { Component, Prop, Vue } from "vue-property-decorator";

type Heading = "Vorlesung" | "Seminar" | "Übung" | "Praktikum" | "Tutorien/AGs" | "Workshop" | "Sonstige";
type QuestRow = {
	partner: string;
	Vorlesung?: boolean;
	Seminar?: boolean;
	Übung?: boolean;
	Praktikum?: boolean;
	Tutorium?: boolean;
	Workshop?: boolean;
	Sonst?: boolean;
};
@Component
export default class QuestionnaireTable extends Vue {
	// @Prop({ required: true }) headings!: string[];
	// @Prop({ required: true }) partners!: ;
	readonly headings: Heading[] = ["Vorlesung", "Seminar", "Übung", "Praktikum", "Tutorien/AGs", "Workshop", "Sonstige"];
	readonly rows: QuestRow[] = [
		{
			partner: "Angwandte Sprachwissenschaft",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "Anglistik",
			Vorlesung: true,
			Seminar: true,
			Übung: true,
			Sonst: true,
		},
		{
			partner: "Bildungswissenschaften",
			Vorlesung: true,
			Seminar: true,
			Praktikum: true,
			Sonst: true,
		},
		{
			partner: "Biologie",
			Vorlesung: true,
			Seminar: true,
			Praktikum: true,
			Sonst: true,
		},
		{
			partner: "Betriebswirtschaftslehre",
			Vorlesung: true,
			Übung: true,
			Sonst: true,
		},
		{
			partner: "Chemie",
			Vorlesung: true,
			Praktikum: true,
		},
		{
			partner: "Computerlinguistik",
			Vorlesung: true,
			Seminar: true,
			Übung: true,
			Sonst: true,
		},
		{
			partner: "DFKI",
			Vorlesung: true,
			Seminar: true,
			Tutorium: true,
			Workshop: true,
			Sonst: true,
		},
		{
			partner: "Edutech",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "Europäische Traumkulturen",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "Fachdidaktik Chemie",
			Vorlesung: true,
			Praktikum: true,
		},
		{
			partner: "Fachdidaktik Germanistik",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "Fachdidaktik Germanistik",
			Vorlesung: true,
			Seminar: true,
			Sonst: true,
		},
		{
			partner: "Geschichte",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "GradUS",
			Workshop: true,
			Sonst: true,
		},
		{
			partner: "HoK",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "Informatik",
			Vorlesung: true,
			Seminar: true,
			Praktikum: true,
			Tutorium: true,
			Sonst: true,
		},
		{
			partner: "Materialwissenschaft & Werkstofftechnik",
			Vorlesung: true,
			Übung: true,
			Praktikum: true,
		},
		{
			partner: "Mathematik",
			Vorlesung: true,
			Übung: true,
		},
		{
			partner: "Medizin",
			Sonst: true,
		},
		{
			partner: "Optionalbereich",
			Seminar: true,
			Sonst: true,
		},
		{
			partner: "Pharmazie",
			Vorlesung: true,
			Seminar: true,
			Praktikum: true,
			Tutorium: true,
		},
		{
			partner: "Philologie",
			Seminar: true,
		},
		{
			partner: "Philosophie",
			Sonst: true,
		},
		{
			partner: "Physik",
			Vorlesung: true,
			Tutorium: true,
			Praktikum: true,
			Sonst: true,
		},
		{
			partner: "ProWis",
			Workshop: true,
			Sonst: true,
		},
		{
			partner: "Psychologie",
			Vorlesung: true,
			Seminar: true,
			Sonst: true,
		},
		{
			partner: "Rechtswissenschaften",
			Vorlesung: true,
			Tutorium: true,
		},
		{
			partner: "Romanistik",
			Vorlesung: true,
			Seminar: true,
			Übung: true,
		},
		{
			partner: "Sportwissenschaften",
			Sonst: true,
		},
		{
			partner: "Systembiotechnologie",
			Vorlesung: true,
			Seminar: true,
			Übung: true,
		},
		{
			partner: "Theologie (katholisch)",
			Vorlesung: true,
			Seminar: true,
		},
		{
			partner: "Zentrum für lebenslanges Lernen",
			Workshop: true,
			Sonst: true,
		},
		{
			partner: "Zentrum für Schlüsselkompetenz",
			Workshop: true,
			Sonst: true,
		},
	];
}
